/**
    Format for Prod stage.
*/
ul.menu-list-prod {
    display: flex;
    margin: 0;
    padding: 0 30px;
    background: #343a40;
    list-style: none;
    height: 4em;
}

ul.menu-list-prod > li {
    padding: 5px 4px 9px;
    font-weight: bold;
}

ul.menu-list-prod > li > div.stage-text-prod {
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif, Serif;
    font-style: italic;
    color: GoldenRod;
    position: absolute;
    margin: 9px;
    margin-left: 0;
    cursor: pointer;
}

ul.menu-list-prod > li > div.badge-photo > img {
    border-radius: 50%;
    border: darkgrey 1px;
    height: 80%;
}

ul.menu-list-prod > li > div.call-dropdown {
    margin-top: 7px;
    position: absolute;
    right: 4%;
}

ul.menu-list-prod > li > div.badge-photo {
    height: 4em;
    position: absolute;
    right: 1%;
}

/**
  Format for other non-prod stages.
*/
ul.menu-list-other {
    display: flex;
    margin: 0;
    padding: 0 30px;
    background: #ff9900;
    list-style: none;
    height: 4em;
}

ul.menu-list-other > li {
    padding: 5px 4px 9px;
    font-weight: bold;
}

ul.menu-list-other > li > div.stage-text-other {
    font-size: 18px;
    font-weight: bold;
    font-family: sans-serif, Serif;
    font-style: italic;
    color: #16191f;
    position: absolute;
    margin: 9px;
    margin-left: 0;
    cursor: pointer;
}

ul.menu-list-other > li > div.badge-photo > img {
    border-radius: 50%;
    border: darkgrey 1px;
    height: 80%;
}

ul.menu-list-other > li > div.badge-photo {
    height: 4em;
    position: absolute;
    right: 1%;
}

ul.menu-list-other > li > div.call-dropdown {
    margin-top: 7px;
    position: absolute;
    right: 4%;
}

body {
    overflow-y: hidden;
}